<template>
  <div>
    <!-- pic583B71EB-667C-4707-928C-D23F8049520F.jpg -->
    <img class="rounded img-large" src="">

    <p>
      The LEGO Universe News Network has just received this transmission from Professor Brickkeeper:
    </p>
    <p>
      <i>Earth friends,</i>
    </p>
    <p>
      <i>Beware of sinister tricks! My data says that the Maelstrom may have infected the <router-link to="/help-the-minifigs/mission/171661">Great Minifig Mission </router-link> website!!</i>
    </p>
    <p>
      <i>Don't stop sending minifigs to my Universe-- even if you're told that trillions have already been sent! The dark menace closes in on the final fragment of Pure Imagination!</i>
    </p>
    <p>
      <i>Head for the <router-link :to="{ name: 'creation-lab-list', params: {challengeId: '0dce5bbb-f17b-41b5-8cf8-367574c30029'} }">Creation Lab</router-link> at hyperspeed to complete your new mission! Build a tower to help shield the last shard of Pure Imagination from evil! Your creation should also be a home base for the mighty Nexus Force, leaders in the battle to save imagination!</i>
    </p>
  </div>
</template>
